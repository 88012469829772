.App {
    background-color: black;
}
.center{
    display: flex;
    padding-top: 10%;
    padding-left: 20%;
}
.terminal {
    font-size: 1.5em;
    color: white;
}
.username {
    color: #61dafb;
}
.colon {
    color: white;
}
.filesystem {
    color: pink;
}

/* Media screen size */
@media screen and (max-width: 768px) {
    .terminal {
        font-size: 0.75em;
    }
}