/* Elements */
li {
    padding: 10px 0;
}

button {
    font-size: 30px;
    margin-right: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    float: left;
    color: white;
}

/* Class */
.navBar {
    width: 100%;
    background-color: rgba(0,0,0,0.1);
    color: #61dafb;
    padding: 10px;
}

.nav-links {
  height: 0;
  overflow: hidden;
  list-style-type: none;
  font-weight: bold;
}

a {
    text-decoration: none;
    color: white;
}

img.logo {
    height: 50px;
}

.show-nav {
    height: 120px;
}

/* Media screen size */
@media screen and (min-width: 768px) {
    button {
        display: none;
    }
    .navBar {
        display: flex;
    }
    .nav-links {
        height: auto;
        display: flex;
    }

    li {
        padding: 0 15px;
    }
}