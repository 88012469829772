
.container {
    padding-top: 10%;
    padding-left: 20%;
    padding-right: 20%;
    text-align: justify;
}

.about-text {
    color: white;
}

.about-text > p > a {
    text-decoration: underline;
}

.hidden-terminal {
    display: none;
}

.indent {
    padding-left: 40px;
}